import { CustomRenderFields } from "common/form/renderFields";
import { CashSummaryReportCriteriaDto } from "generated";

import { getCommonFields } from "./parametersCommon";

export const cashSummaryReportDefaultParams: Partial<CashSummaryReportCriteriaDto> = {
  reportName: "CashSummaryReport",
};

export const cashSummaryReportFields: CustomRenderFields[] = [...getCommonFields()];
