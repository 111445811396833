import { Certificate, QrCode } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { ActionButton } from "base/components";
import { useFlags } from "base/featureFlags";
import { Tooltip, useModalComponent } from "common/guideline";

import { DeleteMachine } from "../../components";

import { RowMachineViewDataProps } from "./types";

export const RowActions: React.FC<RowMachineViewDataProps> = ({ row }) => {
  const { otaPages } = useFlags();
  const canMachineLicenseContentChange = usePermissionAccess("canMachineLicenseContentChange");

  const [show] = useModalComponent({
    Component: (
      <DeleteMachine nodeId={row.original.machine?.nodeId as string} name={row.original.machine?.name as string} />
    ),
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      {canMachineLicenseContentChange && (
        <Tooltip content="License Management">
          <Link route="MACHINE_LICENSE_CONTENT_EDIT" params={{ nodeId: row.original.machine?.nodeId as string }}>
            <ActionButton.Edit Icon={Certificate} />
          </Link>
        </Tooltip>
      )}
      <Link route="MACHINE_EDIT" params={{ nodeId: row.original.machine?.nodeId as string }}>
        <ActionButton.Edit />
      </Link>
      {otaPages && (
        <Tooltip content="OTA Key Pair Management">
          <Link route="OTA_KEY_PAIR" params={{ machineUuid: row.original.machine?.uuid as string }}>
            <ActionButton.Edit Icon={QrCode} />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};
