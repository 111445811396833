import { TKeys } from "i18next";
import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";
import { getRemoteMgmtSection } from "common/remoteMgmt";
import { SmartLocationGroupDtoIn } from "generated";
import { getSelectLocationsField } from "location/components";

import { getCitiesField } from "./CitiesField";
import { getCountriesField } from "./CountriesField";

export type LocationGroupFormData = Pick<
  SmartLocationGroupDtoIn,
  "nodeId" | "name" | "authorizedUsers" | "countries" | "cities" | "locationNodeIds"
> & {
  timeOption?: string;
  remoteMgmtOption?: string;
  templateNodeId?: string;
  softwarePackageNodeId?: string;
  scheduleInfo?: string;
  createdBy?: string;
};

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const getFields: () => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "administration.lg.name",
        validate: { type: "string", required: true },
      },
      getSelectLocationsField({
        name: "locationNodeIds",
        label: "administration.lg.includedSites",
        validate: { type: "array", required: true, minLength: 1, of: { type: "string" } },
      }),
      getCountriesField({ name: "countries", defaultValue: [] }),
      getCitiesField({ name: "cities", defaultValue: [] }),
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<LocationGroupFormData>;
  submitLabel: TKeys;
  initialData?: LocationGroupFormData;
};

export const LocationGroupForm: React.FC<Props> = ({ onSubmit, submitLabel, initialData: initial }) => {
  const fields = useMemo(() => getFields().concat(getRemoteMgmtSection()), []);

  return (
    <SchemaForm<LocationGroupFormData>
      fields={fields}
      initial={initial}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "LOCATION_GROUPS" })}
            data-test="goBack"
          >
            <Text tKey="administration.lg.goBack" />
          </Button>
          <FormData type="isSubmittig">
            {(isLoading) => (
              <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                <Text tKey={submitLabel} />
              </LoadingButton>
            )}
          </FormData>
        </div>
      )}
    />
  );
};
