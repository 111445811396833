type OS = "Windows" | "MacIntel" | "linux" | "unknown";

const baseServicePath = process.env.REACT_APP_SERVICE_PATH || window.location.origin;
const identityPath = baseServicePath.replace(
  process.env.REACT_APP_CONNECT_PATH_NAME as string,
  process.env.REACT_APP_IDENTITY_PATH_NAME as string,
);

export const CONFIG = {
  isProd: process.env.NODE_ENV === "production",
  userOS: ((navigator as any)?.userAgentData?.platform || navigator?.platform || "unknown") as OS,
  gqlPath: process.env.REACT_APP_GQL_PATH as string,
  gqlOnlineStatusPath: process.env.REACT_APP_GQL_ONLINE_STATUS_PATH as string,
  gqlAlertsPath: process.env.REACT_APP_GQL_ALERT_PATH as string,
  gqlScheduledReportsPath: process.env.REACT_APP_GQL_SCHEDULED_REPORTS_PATH as string,
  tokenPath: process.env.REACT_APP_TOKEN_PATH as string,
  oauthPath: process.env.REACT_APP_OAUTH,
  tenantHeaderName: "X-TENANT-ID" as string,
  deployInfoPath: process.env.REACT_APP_DEPLOY_INFO_PATH as string,
  // should come from public/index.html `%PUBLIC_URL%/config/config.js` script file
  // default to hardcoded value from .env and .env.local files
  darklyId: (window as any)?.REACT_APP_DARKLY_ID || (process.env.REACT_APP_DARKLY_ID as string),
  mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
  wso: {
    forgotUsernameLink: `${identityPath}${process.env.REACT_APP_WSO_FORGOT_USERNAME as string}`,
    forgotPasswordLink: `${identityPath}${process.env.REACT_APP_WSO_FORGOT_PASSWORD as string}`,
    changePasswordLink: (domain?: string) =>
      `${identityPath}${
        domain
          ? (process.env.REACT_APP_WSO_CHANGE_PASSWORD_DOMAIN as string).replace(
              process.env.REACT_APP_WSO_DOMAIN_REPLACE as string,
              domain,
            )
          : (process.env.REACT_APP_WSO_CHANGE_PASSWORD as string)
      }`,
  },
} as const;
