import { Fragment, useRef } from "react";
import tw, { styled, theme } from "twin.macro";
import "styled-components/macro";

import { cycleNumberInRange, getNumberWithNDigits, prependNumberAsStringToLength } from "common/helpers";

import { FieldCommonsData, RawFieldData, fieldFactory } from "../schema";

import { FieldBoxVariantsProp, TextLabel, fieldBoxStyle, fieldBoxVariants, fieldWrapperStyle } from "./Common";

type Range = {
  length: number;
  max?: number;
  min?: number;
  leadingZero?: boolean;
};

export type NumberRangeProps = Omit<FieldCommonsData, "value" | "onChange"> & {
  name: string;
  type: "numberRange";
  value: Array<number>;
  onChange?: (value: Array<number>) => void;
  ranges: Array<Range>;
  label?: string;
};

const Box = styled.div<FieldBoxVariantsProp & { error?: any }>`
  ${fieldBoxStyle}
  ${fieldBoxVariants}
  ${tw`flex justify-between items-center`}
`;

const NumberPartInput = styled.div`
  background-color: transparent;
  width: fit-content;
  padding: 1px 2px;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: rgba(${theme`colors.contrast.dark`}, 0.2);
  }
`;

const changeRangeValue = (range: Range, value: number, toChange: number, addition = false) => {
  const newValue = addition ? value + toChange : toChange;
  const max = range.max ?? getNumberWithNDigits(range.length);
  const min = range.min ?? 0;
  return cycleNumberInRange(newValue, min, max);
};

export const NumberRangeRaw: React.FC<RawFieldData<NumberRangeProps>> = ({
  label,
  ranges,
  value = ranges.map(() => 0),
  error,
  onChange,
}) => {
  const lastRangeIndex = ranges.length - 1;
  const ref = useRef<HTMLDivElement | null>(null);
  const updateRangeAtIndex = (index: number, newValue: number) =>
    onChange(value.map((v, i) => (i === index ? newValue : v)));

  const focusRange = (index: number) => {
    const rangeInput = ref.current?.querySelector(`[data-range-index="${index}"]`) as HTMLDivElement | null;
    rangeInput?.focus();
  };

  return (
    <div
      ref={ref}
      css={fieldWrapperStyle}
      onClick={() => {
        const isRangeInput = (document.activeElement as HTMLElement | undefined)?.dataset?.rangeIndex !== undefined;

        if (isRangeInput) return;

        focusRange(lastRangeIndex);
      }}
    >
      {label && <TextLabel error={error}>{label}</TextLabel>}
      <Box error={error}>
        <div tw="flex">
          {value.map((v, index) => (
            <Fragment key={index}>
              <NumberPartInput
                inputMode="numeric"
                tabIndex={0}
                data-range-index={index}
                contentEditable
                spellCheck="false"
                autoCapitalize="off"
                autoCorrect="off"
                onKeyDown={(e) => {
                  e.preventDefault();

                  const isNumberTyped = !isNaN(parseInt(e.key, 10));
                  const isBackspace = e.key === "Backspace";

                  if (isNumberTyped || isBackspace) {
                    const currentValueAsString = prependNumberAsStringToLength(v, ranges[index].length);
                    const newValueAsString = isNumberTyped
                      ? currentValueAsString.slice(1) + e.key
                      : currentValueAsString.slice(0, -1);
                    const newValue = parseInt(newValueAsString, 10);
                    updateRangeAtIndex(index, changeRangeValue(ranges[index], v, newValue));
                  }

                  const changeValue = e.key === "ArrowUp" ? 1 : e.key === "ArrowDown" ? -1 : false;
                  if (changeValue !== false) {
                    updateRangeAtIndex(
                      index,
                      changeRangeValue(ranges[index], v, changeRangeValue(ranges[index], v, changeValue, true)),
                    );
                  }

                  const moveFocus = e.key === "ArrowLeft" ? -1 : e.key === "ArrowRight" ? 1 : false;
                  if (moveFocus !== false) {
                    const indexToFocus = cycleNumberInRange(index + moveFocus, 0, lastRangeIndex);
                    focusRange(indexToFocus);
                  }
                }}
              >
                {!ranges[index].leadingZero || String(v).length === ranges[index].length
                  ? v
                  : prependNumberAsStringToLength(v, ranges[index].length)}
              </NumberPartInput>
              <span>{index < lastRangeIndex && ":"}</span>
            </Fragment>
          ))}
        </div>
      </Box>
      {error && <TextLabel error={error}>{error}</TextLabel>}
    </div>
  );
};

export const NumberRangeField = fieldFactory(NumberRangeRaw);
