import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { Navigate, history, navigateTo } from "appRouting";
import { ReactComponent as LogoSVG } from "assets/img/Login.svg";
import { useAuth } from "auth/hooks";
import { CONFIG } from "common/config";
import { SchemaForm } from "common/form";
import { CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { LoadingButton, Text } from "common/guideline";
import { PreviousRouteState } from "routing/history/withPreviousRoute";

const Wrapper = styled.div`
  ${tw`px-4 sm:px-6 lg:px-8 flex-col flex items-start justify-end min-h-screen py-12`}
  background-image: url("img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
`;

const FieldWrapper = styled.div`
  ${tw`pb-4 space-y-4`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: FieldWrapper,
    fields: [
      {
        type: "text",
        name: "username",
        label: "user.userName",
        validate: { type: "string", required: true },
      },
      {
        type: "password",
        name: "password",
        label: "user.password",
        validate: { type: "string", required: true },
      },
    ],
  },
];

type FormData = {
  username: string;
  password: string;
};

const getPreviousPath = () =>
  (history.location.state as PreviousRouteState)?.previousPath ?? history.getPreviousRoute()?.location?.pathname;

export const Login = () => {
  const [error, loading, isAuthenticated] = useAuth((s) => [s.error, s.loading, s.isAuthenticated]);
  const [previousPath] = useState(getPreviousPath);

  return isAuthenticated ? (
    <Navigate route="HOME" replace />
  ) : (
    <Wrapper>
      <div tw="sm:max-w-md w-full">
        <div tw="flex justify-start w-auto text-white mb-4">
          <LogoSVG />
        </div>
      </div>
      <div tw="sm:max-w-md w-full">
        <div tw="sm:rounded-lg sm:px-10 bg-gray-1 px-4 py-8 shadow">
          <SchemaForm<FormData>
            fields={fields}
            customRender={customRender}
            onSubmit={(data) =>
              useAuth.actions.logIn(data).then((success) => {
                if (success) {
                  previousPath ? history.push(previousPath) : navigateTo({ route: "HOME" });
                }
              })
            }
            SubmitComponent={() => (
              <div tw="flex flex-col gap-4">
                <Text withoutI18n variant="paragraph">
                  <Text tKey="auth.forgot" />{" "}
                  <a href={CONFIG.wso.forgotUsernameLink} target="_blank" rel="noreferrer">
                    <Text variant="link" tKey="auth.username" tw="underline" />
                  </a>{" "}
                  <Text tKey="auth.or" />{" "}
                  <a href={CONFIG.wso.forgotPasswordLink} target="_blank" rel="noreferrer">
                    <Text variant="link" tKey="auth.password" tw="underline" />
                  </a>
                  ?
                </Text>

                <div tw="flex items-start justify-between gap-2">
                  <TextLabel error>{error?.tError || ""}</TextLabel>
                  <LoadingButton tw="shrink-0" type="submit" disabled={loading} isLoading={loading}>
                    <Text tKey="auth.signIn" />
                  </LoadingButton>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Wrapper>
  );
};
