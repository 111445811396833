import { FileCode } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Navigate, useParams } from "appRouting";
import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { usePermissionAccess } from "auth/hooks";
import { Confirm, EntityDelete, PageLayout } from "base/components";
import { useFlagsWithLoading } from "base/featureFlags";
import { InputRaw } from "common/form/renderFields";
import { Breadcrumb, Button, LoadingButton, Text, Tooltip, useModal, useToast } from "common/guideline";
import { copyToClipboard, downloadFile, evictQuery } from "common/helpers";
import {
  GetPublicKeyDocument,
  useCreateOtaKeyPairMutation,
  useDeactivateOtaKeyPairMutation,
  useGetPublicKeyQuery,
} from "generated";

export type OTAKeyPairRouteParams = {
  machineUuid: string;
};

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "ota.list",
    route: "OTA_LIST",
  },
];

export const OTAKeyPair = () => {
  const canOTA = usePermissionAccess("canOTA");
  const { flags, isLoading } = useFlagsWithLoading();
  const { machineUuid = "" } = useParams<OTAKeyPairRouteParams>();
  const {
    previousData,
    data = previousData,
    loading,
  } = useGetPublicKeyQuery({
    skip: !machineUuid,
    variables: {
      machineUuid,
    },
  });
  const publicKey = data?.getPublicKey;

  const [createOtaKeyPair, generateMutationInfo] = useCreateOtaKeyPairMutation({
    update: (cache) => evictQuery(GetPublicKeyDocument, cache),
    variables: {
      machineUuid,
      requestKey: "OR1",
    },
  });
  const [deactivateOtaKeyPair, deactivateMutationInfo] = useDeactivateOtaKeyPairMutation({
    update: (cache) => evictQuery(GetPublicKeyDocument, cache),
    variables: {
      machineUuid,
    },
  });

  const keyPairRegenerate = () => {
    const modalId = useModal.actions.open({
      Component: (
        <Confirm
          confirmText="ota.kp.confirm"
          rejectText="ota.kp.cancel"
          desc="ota.kp.keyPairRegenerate"
          onConfirm={() => {
            createOtaKeyPair()
              .then((res) => {
                const newPublicKey = res.data?.createOtaKeyPair;

                if (!newPublicKey) throw new Error();

                useToast.actions.show("ota.kp.regenerateSuccess", { variant: "success" });
                useModal.actions.close(modalId);
              })
              .catch(() => {
                useToast.actions.show("ota.kp.regenerateError", { variant: "error" });
              });
          }}
        >
          <Text tKey="ota.kp.keyPairRegenerateWarning" />
        </Confirm>
      ),
    });
  };

  const keyPairCreate = () => {
    const modalId = useModal.actions.open({
      Component: (
        <Confirm
          confirmText="ota.kp.confirm"
          rejectText="ota.kp.cancel"
          desc="ota.kp.keyPairCreate"
          onConfirm={() => {
            createOtaKeyPair()
              .then((res) => {
                const newPublicKey = res.data?.createOtaKeyPair;

                if (!newPublicKey) throw new Error();

                useToast.actions.show("ota.kp.createSuccess", { variant: "success" });
                useModal.actions.close(modalId);
              })
              .catch(() => {
                useToast.actions.show("ota.kp.createError", { variant: "error" });
              });
          }}
        >
          <Text tKey="ota.kp.keyPairCreateInfo" />
        </Confirm>
      ),
    });
  };

  const deactivateKeyPair = () => {
    const modalId = useModal.actions.open({
      Component: (
        <EntityDelete
          actionText="ota.kp.deleteKeyPair"
          onDelete={() => {
            deactivateOtaKeyPair()
              .then((res) => {
                const success = res.data?.deactivateOtaKeyPair;

                if (!success) throw new Error();

                useToast.actions.show("ota.kp.deleteSuccess", { variant: "success" });
                useModal.actions.close(modalId);
              })
              .catch(() => {
                useToast.actions.show("ota.kp.deleteError", { variant: "error" });
              });
          }}
        >
          <Text tKey="ota.kp.deleteKeyPairInfo" />
        </EntityDelete>
      ),
    });
  };

  return !isLoading && !flags.otaPages ? (
    <Navigate route="HOME" replace />
  ) : (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="ota.kp.title"
      actions={
        canOTA ? (
          <div tw="flex gap-2">
            <LoadingButton
              isLoading={generateMutationInfo.loading || loading}
              onClick={publicKey ? keyPairRegenerate : keyPairCreate}
            >
              <Text tKey={publicKey ? "ota.kp.keyPairRegenerate" : "ota.kp.keyPairCreate"} />
            </LoadingButton>

            {publicKey ? (
              <LoadingButton variant="error" isLoading={deactivateMutationInfo.loading} onClick={deactivateKeyPair}>
                <Text tKey="ota.kp.deleteKeyPair" />
              </LoadingButton>
            ) : null}
          </div>
        ) : null
      }
    >
      <div>
        {publicKey && (
          <div tw="flex gap-2 items-center flex-1 text-ellipsis">
            <div tw="flex flex-col gap-2">
              <Tooltip content={<Text tKey="common.clipboardCopy" />}>
                <Button
                  variant={["smSquare", "ghost"]}
                  onClick={() => copyToClipboard(publicKey, () => useToast.actions.show("common.clipboard"))}
                >
                  <CopySVG width={20} height={20} />
                </Button>
              </Tooltip>

              <Tooltip content={<Text tKey="ota.kp.downloadAsFile" />}>
                <Button
                  variant={["smSquare", "ghost"]}
                  onClick={() => downloadFile("OTA_public_key", "text/plain", publicKey)}
                >
                  <FileCode tw="text-primary-default w-5 h-5" />
                </Button>
              </Tooltip>
            </div>
            <InputRaw
              tw="flex-1"
              type="textarea"
              label="ota.kp.publicKey"
              onChange={() => null}
              value={loading ? "..." : publicKey}
              name="publicKey"
              rows={4}
              autoCorrect="off"
              autoComplete="off"
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
};
