export const en = {
  ota: {
    title: "OTA",
    list: "OTA list",
    listPageDesc: "The purpose of OTA codes is to limit the access to a device to when it is needed, and only once.",
    deactivateTitle: "Deactivate OTA authorization",
    deactivateDescription:
      "Are you sure you want to deactivate this OTA? Deactivating OTA authorization will prevent Connect users from generating new OTA codes. OTA codes that were generated below will remain valid until they expire or until they expire or until they are used for machine-side authentication",
    connectUser: "Connect user",
    machineUserId: "Machine user ID",
    machineUserName: "Machine user name",
    machineUserRole: "Machine user role",
    customRole: "Custom role",
    machineName: "Machine name",
    authorization: "Authorization",
    authorizedMachines: "Authorized machines",
    authorizedSites: "Authorized sites",
    authorizedMachineGroups: "Authorized machine groups",
    expiration: "Expiration",
    validityTime: "Valid for",
    validityTimeInterval: "Valid for (d:hh:mm)",
    nodeStatus: "Node status",
    customData: "Custom data",
    create: "Create OTA authorization",
    createDesc: "Create a new OTA authorization",
    deleteAt: "Delete at",
    deleteAfterFirstUse: "Delete after first use",
    key: "Key",
    value: "Value",
    failedToGetCode: "Failed to get code",
    validityDesc:
      "Validity indicates how long generated OTA code is valid for after it was generated. Value from 1 minute to 7 days",
    authorizationTypes: {
      allMachines: "All machines",
      oneMachine: "Machine",
      oneSite: "Site",
      oneSiteGroup: "Site group",
    },
    createSuccess: "OTA authorization created successfully",
    createError: "Failed to create OTA authorization",
    deactivateSuccess: "OTA authorization deactivated successfully",
    deactivateError: "Failed to deactivate OTA authorization",
    kp: {
      title: "OTA key pair management",
      publicKey: "OTA public key",
      keyPairRegenerate: "Regenerate OTA key pair",
      keyPairCreate: "Create OTA key pair",
      deleteKeyPair: "Delete OTA key pair",
      confirm: "Confirm",
      cancel: "Cancel",
      keyPairRegenerateWarning:
        "Are you sure you want to regenerate OTA keys? Regenerating OTA key pair will result in invalidating all the previously generated OTA codes, if machine is online. If machine is offline, it is needed to manually configure new public key on the machine in order to reenable OTA functionality.",
      keyPairCreateInfo:
        "It seems you are generating OTA keys for the first time. Verify that OTA functionality is turned on for this machine. If machine is offline, it may be required to manually configure OTA public key on the machine",
      deleteKeyPairInfo:
        "Are you sure you want to delete OTA keys? Deleting OTA keys will result in invalidating all the previously generated OTA codes, if machine is online. Additionally, OTA functionality will be disabled for given machine until new OTA keys are generated.",
      regenerateSuccess: "OTA key pair regenerated successfully",
      regenerateError: "Failed to regenerate OTA key pair",
      createSuccess: "OTA key pair created successfully",
      createError: "Failed to create OTA key pair",
      deleteSuccess: "OTA key pair deleted successfully",
      deleteError: "Failed to delete OTA key pair",
      downloadAsFile: "Download as file",
    },
  },
};
