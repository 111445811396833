import { CustomRenderFields } from "common/form/renderFields";
import { ScheduledReportName } from "generated";

import { getOneColumnWrapper } from "./common";
import { cashSummaryReportFields } from "./parameters/cashSummaryReport";
import { machineErrorsReportFields } from "./parameters/machineErrorsReport";
import { transactionsReportFields } from "./parameters/transactionsReport";
import { StepType } from "./types";

const getParametersCondition = (reportName: ScheduledReportName, fields: CustomRenderFields[]): CustomRenderFields => ({
  type: "condition",
  when: "reportName",
  is: reportName,
  fields,
});

export const parametersStep: StepType = {
  title: "report.sr.params",
  section: () => ({
    type: "container",
    fields: [
      getOneColumnWrapper([
        // not included
        // BoxTotalsByCurrency
        // RefillReport
        // MachineUserTransactionsReport
        // not yet implemented
        // getParametersCondition("CashInventoryReport", cashInventoryReportFields),
        // getParametersCondition("CashOperationsReport", []),
        getParametersCondition("CashSummaryReport", cashSummaryReportFields),
        // getParametersCondition("ClaimedValuesReport", []),
        // getParametersCondition("MachineDetailsReport", []),
        getParametersCondition("MachineErrorsReport", machineErrorsReportFields),
        // getParametersCondition("ManualDepositsReport", []),
        // getParametersCondition("NetCashReport", []),
        // getParametersCondition("OpenShiftReport", []),
        getParametersCondition("TransactionsReport", transactionsReportFields),
      ]),
    ],
  }),
};
