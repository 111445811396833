import { Info, Plus, Trash } from "@phosphor-icons/react";
import { startOfDay } from "date-fns";
import { TKeys } from "i18next";
import { useEffect } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getSiteGroupsFilterField } from "administration/pages/LocationGroups/components/getSmartLocationsGroup";
import { history, navigateTo } from "appRouting";
import { defaultTimeZone, timeZonesOptions } from "base/helpers";
import { FormData, OnSubmit, SchemaForm, changeFormData, useFieldArray, useFormContext } from "common/form";
import { CustomRenderFields, TextLabel, customRender, getDateFieldFormatters } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";
import { daysHoursAndMinutesToMinutes, minutesToDaysHoursAndMinutes, omitByKey } from "common/helpers";
import { OtaAuthorizationIn } from "generated";
import { getSelectLocationsField } from "location/components";
import { getMachinesFilterField } from "machine/components";
import { getMachineUserRoleField } from "machineUser/fields";
import { useTenant } from "tenant/context";
import { getUsersField } from "user/pages/User/fields";

type OTAFormDataInternal = {
  deviceType: "AllMAchines" | "OneMachine" | "OneSite" | "OneSiteGroup";
  customUserRole: boolean;
  domainName: string;
};

export type OTAFormData = OtaAuthorizationIn & OTAFormDataInternal;

type Props = {
  submitLabel: TKeys;
  onSubmit: OnSubmit<OTAFormData>;
};

const OneColWrapper = styled.div`
  ${tw`flex flex-col gap-4 pb-3 justify-start`}
`;

const OneColWrapper2 = styled.div`
  ${tw`flex flex-col gap-4 pb-3 justify-start pt-5`}
`;

const TwoColsWrapper = styled.div`
  ${tw`grid gap-12 pb-3 sm:grid-cols-2`}
`;

const RoleFieldsWrapper = styled.div`
  ${tw`flex flex-1 items-center gap-2 justify-start [&>*:first-child]:flex-1`}
`;

const deviceTypeOptions: Array<{ value: string; label: string }> = [
  { value: "AllMAchines", label: "ota.authorizationTypes.allMachines" },
  { value: "OneMachine", label: "ota.authorizationTypes.oneMachine" },
  { value: "OneSite", label: "ota.authorizationTypes.oneSite" },
  { value: "OneSiteGroup", label: "ota.authorizationTypes.oneSiteGroup" },
];

const CustomDataField: React.FC<React.PropsWithChildren<{ fieldArrayName: string }>> = ({
  children,
  fieldArrayName,
}) => {
  const { fields, append } = useFieldArray(fieldArrayName);

  return (
    <div>
      {fields.length > 0 ? <TextLabel variant="label" tKey="ota.customData" /> : null}
      {children}
      {fields.length === 0 ? (
        <Button variant={["primary", "withIcon"]} onClick={() => append({})}>
          <Plus />
          <span>Add custom data</span>
        </Button>
      ) : null}
    </div>
  );
};

const CustomDataFieldRow: React.FC<React.PropsWithChildren<{ fieldArrayName: string; index: number }>> = ({
  children,
  fieldArrayName,
  index,
}) => {
  const { fields, remove, append } = useFieldArray(fieldArrayName);

  return (
    <>
      <div tw="flex gap-2 items-end py-2">
        <div tw="flex-1 flex [&>*]:flex-1 gap-2">{children}</div>
        <div tw="flex gap-2 pb-1">
          <Button variant={["error", "smSquare"]} onClick={() => remove(index)}>
            <Trash weight="duotone" />
          </Button>

          {index + 1 === fields.length || fields.length === 0 ? (
            <Button variant={["primary", "smSquare"]} onClick={() => append({})}>
              <Plus weight="duotone" />
            </Button>
          ) : (
            <Button tw="invisible" variant="smSquare" />
          )}
        </div>
      </div>
    </>
  );
};

const keysToOmit: Array<keyof OTAFormDataInternal> = ["deviceType", "customUserRole", "domainName"];
export const omitInternalFields: (data: OTAFormData) => OtaAuthorizationIn = omitByKey(keysToOmit);

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      {
        type: "container",
        Component: OneColWrapper,
        fields: [
          {
            type: "container",
            Component: RoleFieldsWrapper,
            fields: [
              {
                type: "condition",
                when: "customUserRole",
                is: (value) => value === false,
                fields: [
                  getMachineUserRoleField({
                    label: "ota.machineUserRole",
                    name: "userRole",
                    isMulti: false,
                    validate: { type: "string", required: true },
                  }),
                ],
              },
              {
                type: "condition",
                when: "customUserRole",
                is: (value) => value === true,
                fields: [
                  {
                    type: "text",
                    label: "ota.machineUserRole",
                    name: "userRole",
                    validate: { type: "string", required: true },
                  },
                ],
              },
              {
                type: "checkbox",
                name: "customUserRole",
                label: "ota.customRole",
                defaultValue: false,
                calculation: {
                  updates: () => ({ userRole: null }),
                },
              },
            ],
          },
          {
            type: "text",
            label: "ota.machineUserId",
            name: "userId",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "userName",
            label: "ota.machineUserName",
            validate: { type: "string", required: true },
          },
          getUsersField(
            {
              name: "connectUserName",
              label: "ota.connectUser",
              validate: { type: "string", required: true },
            },
            (user) => `${user.userName ?? ""}@${user.domainName ?? ""}`,
            () => {
              const domainName = useTenant((s) => s.domainName);
              const form = useFormContext().useStore;

              useEffect(() => {
                if (domainName) {
                  changeFormData(form, {
                    domainName,
                    userRole: undefined,
                    connectUserName: undefined,
                  });
                }
              }, [domainName, form]);

              return {
                isUserFilterDtoIn: { userRole: "UserRole/ota_user" },
              };
            },
          ),
          {
            type: "radio",
            name: "deviceType",
            label: "ota.authorization",
            values: deviceTypeOptions,
            variant: "sm",
            validate: { type: "string", required: true },
            calculation: {
              updates: () => ({ machineUuid: undefined, siteNodeId: undefined, siteGroupNodeId: undefined }),
            },
          },
          {
            type: "condition",
            when: "deviceType",
            is: (value) => value === "OneMachine",
            fields: [
              getMachinesFilterField(
                {
                  name: "machineUuid",
                  isMulti: false,
                  validate: { type: "string", required: true },
                },
                (machine) => machine.uuid ?? "",
              ),
            ],
          },
          {
            type: "condition",
            when: "deviceType",
            is: (value) => value === "OneSite",
            fields: [
              getSelectLocationsField({
                name: "siteNodeId",
                isMulti: false,
                validate: { type: "string", required: true },
              }),
            ],
          },
          {
            type: "condition",
            when: "deviceType",
            is: (value) => value === "OneSiteGroup",
            fields: [
              getSiteGroupsFilterField({
                name: "siteGroupNodeId",
                isMulti: false,
                validate: { type: "string", required: true },
              }),
            ],
          },
        ],
      },

      {
        type: "container",
        Component: OneColWrapper2,
        fields: [
          {
            type: "checkbox",
            name: "deleteAfterFirstUse",
            label: "ota.deleteAfterFirstUse",
            defaultValue: false,
            calculation: {
              updates: (value) => (value === true ? { expiringAfter: null, zoneId: null } : {}),
            },
          },
          {
            type: "condition",
            when: "deleteAfterFirstUse",
            is: (value) => value === false,
            fields: [
              {
                type: "date",
                name: "expiringAfter",
                label: "ota.deleteAt",
                dateFormat: "datetime",
                fromDate: startOfDay(new Date()),
                validate: (v) => (v ? null : "common.form.err.required"),
                parse: (v) => (v ? `${getDateFieldFormatters("datetime_seconds").parse(v)}.000` : v),
                format: (v) => (v ? getDateFieldFormatters("datetime_seconds").format(v.replace(".000", "")) : v),
              },
              {
                type: "select",
                name: "zoneId",
                label: "base.timeZone",
                isClearable: true,
                defaultValue: defaultTimeZone,
                options: timeZonesOptions,
              },
            ],
          },
          {
            type: "container",
            Component: (props) => (
              <div tw="flex flex-col">
                {props.children}
                <div tw="flex gap-1 items-center">
                  <Info />
                  <Text variant="content" tKey="ota.validityDesc" />
                </div>
              </div>
            ),
            fields: [
              {
                type: "numberRange",
                name: "codeValidityIntervalMinutes",
                label: "ota.validityTimeInterval",
                defaultValue: 1,
                validate: { type: "number", required: true, min: 1 },
                ranges: [
                  { length: 1, max: 7 },
                  { length: 2, leadingZero: true, max: 23 },
                  { length: 2, leadingZero: true, max: 59 },
                ],
                parse: (v) => {
                  if (!v) return 0;
                  const [days = 0, hours = 0, minutes = 0] = v;
                  return daysHoursAndMinutesToMinutes({ days, hours, minutes });
                },
                format: (v) => {
                  if (v === undefined || v === null) return v;
                  const dateRange = minutesToDaysHoursAndMinutes(v ?? 0);
                  return [dateRange.days, dateRange.hours, dateRange.minutes];
                },
              },
            ],
          },
        ],
      },
      {
        type: "container",
        Component: OneColWrapper,
        fields: [
          {
            type: "array",
            name: "customData",
            Component: CustomDataField,
            Row: CustomDataFieldRow,
            defaultValue: [],
            validate: {
              type: "array",
              of: {
                type: "object",
                shape: {
                  key: { type: "string", required: true },
                  value: { type: "string", required: true },
                },
              },
            },
            fields: [
              {
                type: "text",
                name: "key",
                label: "ota.key",
              },
              {
                type: "text",
                name: "value",
                label: "ota.value",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const OTAForm: React.FC<Props> = ({ submitLabel, onSubmit }) => (
  <SchemaForm<OTAFormData>
    fields={fields}
    onSubmit={onSubmit}
    customRender={customRender}
    SubmitComponent={() => (
      <FormData type="isSubmittig">
        {(isLoading) => (
          <div tw="flex justify-between mt-6">
            <Button
              variant="side"
              onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "OTA_LIST" })}
              data-test="goBack"
            >
              <Text tKey="machine.goBack" />
            </Button>
            <LoadingButton type="submit" isLoading={isLoading}>
              <Text tKey={submitLabel} />
            </LoadingButton>
          </div>
        )}
      </FormData>
    )}
  />
);
