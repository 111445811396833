import tw from "twin.macro";
import "styled-components/macro";

import { Navigate, history, useParams } from "appRouting";
import { useAuth } from "auth/hooks";
import { Button, Text } from "common/guideline";
import { OTACodeComponent } from "ota/components/OTACodeComponent";
import { TenantSelect } from "tenant/components";

export type OTACodeRouteParams = {
  machineUuid: string;
};

export const OTACode = () => {
  const user = useAuth(useAuth.actions.getUser);
  const { machineUuid } = useParams<OTACodeRouteParams>();

  return !user ? (
    <Navigate route="LOGIN" state={{ previousPath: history.location.pathname }} />
  ) : !machineUuid ? (
    <Navigate route="HOME" replace />
  ) : (
    <OTACodeComponent machineUuid={machineUuid}>
      <div tw="flex justify-between gap-2 items-end self-stretch">
        <TenantSelect withLabel />

        <Button variant="ghost" onClick={useAuth.actions.logOut}>
          <Text tKey="common.signOut" />
        </Button>
      </div>
    </OTACodeComponent>
  );
};
