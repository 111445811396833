import { TKeys } from "i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FileType, ScheduledReportName } from "generated";

import { InputPayloads } from "../types";

import { cashInventoryReportDefaultParams } from "./parameters/cashInventoryReport";
import { cashSummaryReportDefaultParams } from "./parameters/cashSummaryReport";
import { machineErrorsReportDefaultParams } from "./parameters/machineErrorsReport";
import { transactionsReportDefaultParams } from "./parameters/transactionsReport";
import { StepType } from "./types";

const configForReport: InputPayloads = {
  MachineErrorsReport: machineErrorsReportDefaultParams,
  TransactionsReport: transactionsReportDefaultParams,
  CashInventoryReport: cashInventoryReportDefaultParams,
  CashOperationsReport: {},
  ClaimedValuesReport: {},
  BoxTotalsByCurrency: {},
  MachineUserTransactionsReport: {},
  RefillReport: {},
  MachineDetailsReport: {},
  OpenShiftReport: {},
  CashSummaryReport: cashSummaryReportDefaultParams,
  ManualDepositsReport: {},
  NetCashReport: {},
};

const Wrapper = styled.div`
  ${tw`flex flex-col gap-2`}
`;

export const DEFAULT_FILE_TYPE: FileType = "CSV";
export const fileTypeOptions: Array<{ value: FileType; label: TKeys }> = [
  { value: DEFAULT_FILE_TYPE, label: "report.sr.csv" },
];

export const reportOptions: Array<{ value: ScheduledReportName; label: TKeys }> = [
  { value: "MachineErrorsReport", label: "report.sr.machineErrors" },
  // { value: "CashInventoryReport", label: "report.sr.cashInventoryReport" },
  // { value: "CashOperationsReport", label: "report.sr.cashOperationsReport" },
  // { value: "ClaimedValuesReport", label: "report.sr.claimedValuesReport" },
  // { value: "MachineDetailsReport", label: "report.sr.machineDetailsReport" },
  // { value: "OpenShiftReport", label: "report.sr.openShiftReport" },
  { value: "CashSummaryReport", label: "report.sr.cashSummaryReport" },
  // { value: "ManualDepositsReport", label: "report.sr.manualDepositsReport" },
  //{ value: "NetCashReport", label: "report.sr.netCash" },
  { value: "TransactionsReport", label: "report.sr.transactions" },
];

export const reportStep: StepType = {
  title: "report.sr.report",
  section: () => ({
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "instanceName",
        label: "report.sr.reportName",
        validate: { type: "string", required: true },
      },
      {
        type: "select",
        name: "reportName",
        label: "report.sr.report",
        options: reportOptions,
        validate: { type: "string", required: true },
        calculation: {
          updates: (value) => (value ? { inputPayload: { ...configForReport[value] } } : {}),
        },
      },
    ],
  }),
};
