import { ArrowsClockwise } from "@phosphor-icons/react";
import { QRCodeSVG } from "qrcode.react";
import { useLayoutEffect, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, Spinner, Text } from "common/guideline";
import { useGetOrCreateMutation } from "generated";
import { useTenant } from "tenant/context";

const padding = 8;
const getSize = () => {
  const windowSize = window.innerHeight < window.innerWidth ? window.innerHeight : window.innerWidth;
  return Math.max(200, Math.min(400, windowSize - padding * 4));
};

export const OTACodeComponent: React.FC<React.PropsWithChildren<{ machineUuid: string }>> = ({
  machineUuid,
  children,
}) => {
  const tenantId = useTenant(useTenant.actions.getTenantId);
  const [size, setSize] = useState(getSize);
  const [getCodeMutation, { loading, error, data }] = useGetOrCreateMutation({
    variables: {
      otaCodeDtoIn: {
        machineUuid,
        codeFormatVersion: "1",
      },
    },
  });

  const code = data?.getOrCreate?.code;

  useLayoutEffect(() => {
    if (tenantId) getCodeMutation();
  }, [getCodeMutation, tenantId]);

  useLayoutEffect(() => {
    const onResize = () => setSize(getSize());
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div tw="flex flex-col items-center h-screen overflow-y-auto" style={{ padding }}>
      <div tw="flex-1 flex flex-col justify-center items-center gap-4" style={{ padding }}>
        {children}
        <div tw="bg-white" style={{ padding }}>
          {loading ? (
            <div tw="bg-gray-1 flex items-center justify-center" style={{ height: size, width: size }}>
              <Spinner tw="w-10 h-10" />
            </div>
          ) : error ? (
            <div
              tw="text-error-default bg-gray-1 flex items-center justify-center text-center text-sm p-2"
              style={{ height: size, width: size }}
            >
              {error.message || <Text tKey="ota.failedToGetCode" />}
            </div>
          ) : code ? (
            <QRCodeSVG value={code} size={size} level="L" />
          ) : null}
        </div>
        <Button disabled={loading} variant={["light", "withIcon"]} onClick={() => getCodeMutation()}>
          <ArrowsClockwise size={18} />
          <span>Refresh code</span>
        </Button>
      </div>
    </div>
  );
};
