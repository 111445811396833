export const en = {
  auth: {
    signIn: "Sign in",
    resetPassword: "Reset password",
    forgot: "Forgot",
    username: "Username",
    password: "Password",
    or: "or",
    err: {
      invalid_grant: "Invalid user name or password",
      "17003":
        "Account is locked due to 3 consecutive failed login attempts. It will be locked for 5 minutes. Try again to log in later.",
    },
  },
};
