import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { client } from "client";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllLocationGroupsFilteredCountsDocument,
  FindAllLocationGroupsFilteredDocument,
  FindAllMachinesDocument,
  FindAllMachinesQuery,
  FindAllMachinesQueryVariables,
  GetReportHeaderDocument,
  useUpsertSmartLocationGroupMutation,
} from "generated";

import { LocationGroupForm } from "../components/LocationGroupForm";

import { breadcrumbs } from "./breadcrumbs_create";
import { MachineResult } from "./types";

export const LocationGroupCreate = () => {
  const [creatLocationGroup] = useUpsertSmartLocationGroupMutation();

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="administration.lg.create"
      subtitle="administration.lg.createDescription"
    >
      <LocationGroupForm
        submitLabel="administration.lg.create"
        onSubmit={({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          timeOption,
          remoteMgmtOption,
          templateNodeId,
          softwarePackageNodeId,
          scheduleInfo,
          createdBy,
          ...smartGroup
        }) =>
          creatLocationGroup({
            variables: { smartGroup },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindAllLocationGroupsFilteredDocument, cache);
              evictQuery(FindAllLocationGroupsFilteredCountsDocument, cache);
              evictQuery(GetReportHeaderDocument, cache);
            },
          })
            .then(async (v) => {
              if (!v.data?.upsertSmartLocationGroup?.nodeId) throw new Error();

              const locations = v.data?.upsertSmartLocationGroup?.locations || [];
              const machineNodeIds = locations.flatMap((l) => l?.machineNodeIds ?? []).filter(Boolean);
              const machinesQuery =
                machineNodeIds.length === 0
                  ? null
                  : await client.query<FindAllMachinesQuery, FindAllMachinesQueryVariables>({
                      query: FindAllMachinesDocument,
                      variables: {
                        searchRequest: { page: 0, size: machineNodeIds.length },
                        machineFilters: { nodeIds: machineNodeIds },
                      },
                    });

              const machineUuids = machinesQuery?.data?.findAllMachines?.result?.map?.(
                (m) => (m as MachineResult)?.uuid,
              );

              useToast.actions.show("administration.lg.success", { variant: "success" });

              performRemoteMgmtOperation(
                remoteMgmtOption,
                machineUuids,
                templateNodeId,
                softwarePackageNodeId,
                scheduleInfo,
                createdBy,
              );

              navigateTo({ route: "LOCATION_GROUPS" });
            })
            .catch(() => useToast.actions.show("administration.lg.error", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
