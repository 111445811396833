import { TKeys } from "i18next";

import { defaultTimeZone, timeZonesOptions } from "base/helpers";
import { CustomRenderFields } from "common/form/renderFields";
import { ErrorsReportCriteriaDtoIn, MessageDateTime } from "generated";

import { getCommonFields } from "./parametersCommon";

export const machineErrorsReportDefaultParams: Partial<ErrorsReportCriteriaDtoIn> = {
  reportName: "MachineErrorsReport",
  queryBy: "DATE_TIME",
  zoneId: defaultTimeZone ?? "Europe/Warsaw",
};

const messageDateTimeOptions: Array<{ value: MessageDateTime; label: TKeys }> = [
  {
    value: "DATE_TIME",
    label: "report.sr.dateTime",
  },
  {
    value: "RECEIVED_DATE_TIME",
    label: "report.sr.receivedDateTime",
  },
];

export const machineErrorsReportFields: CustomRenderFields[] = [
  {
    type: "select",
    name: "inputPayload.queryBy",
    label: "report.sr.messageDateTime",
    options: messageDateTimeOptions,
  },
  {
    type: "select",
    name: "inputPayload.zoneId",
    label: "base.timeZone",
    options: timeZonesOptions,
  },
  ...getCommonFields(),
];
