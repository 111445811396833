import { Download, IconProps, ListPlus, Pencil, Trash } from "@phosphor-icons/react";
import "styled-components/macro";

import { Button } from "common/guideline";

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  Icon?: React.FC<IconProps>;
  dataTestId?: string;
};

const buttonVariants = ["smSquare", "ghost"] as ["smSquare", "ghost"];

const ActionButtonBase: React.FC<ButtonProps> = ({ onClick, disabled, dataTestId, Icon = Download }) => (
  <Button
    variant={buttonVariants}
    tw="text-gray-6 transition-all duration-300 hover:text-primary-default"
    onClick={onClick}
    disabled={disabled}
    data-test={dataTestId}
  >
    <Icon weight="duotone" size={18} />
  </Button>
);

const withIcon = (Icon: React.FC<IconProps>, dataTestId = `${Icon.displayName}Action`) =>
  function ActionButton(props: ButtonProps) {
    return <ActionButtonBase Icon={Icon} {...props} dataTestId={dataTestId} />;
  };

export const ActionButton = {
  Delete: withIcon(Trash),
  Edit: withIcon(Pencil),
  Download: withIcon(Download),
  AddMultiple: withIcon(ListPlus),
};
